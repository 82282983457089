<template>
  <div class="indexshow">
    <div class="box">
      <div class="banner">
        <img src="@/assets/image/index_bg.png" />
      </div>
      <div class="container">
        <!--限时优惠特区-->
        <div class="content" v-if="shopTypeOne">
          <div class="tit"><span>限时优惠特区</span></div>
          <ul class="nav-list">
            <li
              v-for="item in shopTypeOne"
              :key="item.id"
              @click="link(item.id, item.type)"
            >
              <van-image class="logImage" fit="cover" :src="item.poster" />
            </li>
          </ul>
        </div>

        <div class="content">
          <div v-if="shopTypecx">
            <div class="tit">
              <span>出行服务</span>
            </div>
            <div
              class="link"
              v-for="item in shopTypecx"
              :key="item.id"
              @click="link(item.id, item.type)"
            >
              <!-- <van-image :src="item.poster" cover /> -->
              <van-image class="logImage" fit="cover" :src="item.poster" />
            </div>
          </div>
          <div v-if="shopTypems">
            <div class="tit">
              <span>美食人生</span>
            </div>
            <div
              class="link"
              v-for="item in shopTypems"
              :key="item.id"
              @click="link(item.id, item.type)"
            >
              <!-- <van-image :src="item.poster" cover /> -->
              <van-image class="logImage" fit="cover" :src="item.poster" />
            </div>
          </div>
          <div v-if="shopTypejy">
            <div class="tit">
              <span>教育学习</span>
            </div>
            <div
              class="link"
              v-for="item in shopTypejy"
              :key="item.id"
              @click="link(item.id, item.type)"
            >
              <!-- <van-image :src="item.poster" cover /> -->
              <van-image class="logImage" fit="cover" :src="item.poster" />
            </div>
          </div>
          <div v-if="shopTypesh">
            <div class="tit">
              <span>生活娱乐</span>
            </div>
            <div
              class="link"
              v-for="item in shopTypesh"
              :key="item.id"
              @click="link(item.id, item.type)"
            >
              <!-- <van-image :src="item.poster" cover /> -->
              <van-image class="logImage" fit="cover" :src="item.poster" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <tab-bar /> -->
  </div>
</template>
<script>
import { goodsapi} from "@/request/api";

export default {
  components: {
    // tabBar,
  },
  data() {
    return {
      data: {
        offset: 0,
        limit: 100,
        // shop_id: 14,
      },
      shopList: [],
    };
  },
  created() {
    this.shopsapi();
  },
  methods: {
    //   详情页跳转
    link(id, type) {
      // this.$store.commit("rollModules/addrollid", id);
      this.$router.push({
        path: `/rolldetail?id=${id}&type=${type}&sourceid=${'001065'}&scode=${'SV702180000089'}&token=${'62500b4324c3890b5a3d4de03c5508d00293f07105b0465f6dd85fff82934c07'}`
      });
    },
    shopsapi() {
      goodsapi(this.data).then((res)=>{
        this.shopList = res.data.data;
        // this.shopList = res.data.data.filter(function (item) {
        //   return item.price > 0;
        // });
      })
    },
  },
  // 通过计算属性将不同的类型分类
  computed: {
    shopTypeOne: function () {
      //出行服务
      return this.shopList.filter(function (item) {
        return item.type == 1;
      });
    },
    shopTypecx: function () {
      //出行服务
      return this.shopList.filter(function (item) {
        return item.type == 2;
      });
    },
    shopTypems: function () {
      //美食人生
      return this.shopList.filter(function (item) {
        return item.type == 3;
      });
    },
    shopTypejy: function () {
      //教育学习
      return this.shopList.filter(function (item) {
        return item.type == 4;
      });
    },
    shopTypesh: function () {
      //生活娱乐
      return this.shopList.filter(function (item) {
        return item.type == 5;
      });
    },
  },
};
</script>

<style lang="less">
.indexshow {
  width: 100%;
  height: 100%;
  background-color: #292729;
  padding: 0;
  margin: 0;
  .box {
    font-size: 14px;
    color: #333;
    margin: 0 auto;
    max-width: 750px;
    min-width: 320px;
    .banner {
      img {
        width: 100%;
      }
    }
    .container {
      padding: 18px 12px;
      .content {
        margin-bottom: 20px;
        .tit {
          position: relative;
          padding-left: 14px;
          margin-bottom: 20px;
          span {
            font-size: 16px;
            color: #f2d6b2;
            font-weight: 600;
          }
        }
        .tit::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 4px;
          height: 18px;
          margin-top: -9px;
          background: #f2d6b2;
          z-index: 2;
        }
        .nav-list {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 30%;
            margin-right: 5%;
            margin-bottom: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          li:nth-child(3n) {
            margin-right: 0;
          }
        }
        .link {
          margin-bottom: 10px;
          width: 100%;
          .logImage {
            width: 100%;
            height: 6rem;
          }
        }
      }
    }
  }
}
</style>
